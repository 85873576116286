import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { AnimationService, LanguageService } from '@tcc-mono/shared/ui/theming';
import { VanDijkAppShellRoutingModule } from './vandijk-app-shell-routing.module';
import { registerLocaleData } from '@angular/common';
import nl from '@angular/common/locales/nl';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { AnimalSelectionShellModule } from '@tcc-mono/vandijk/shared/features/animal-selections/feature/animal-selection-shell';
import { TccCoreAuthModule } from '@tcc-mono/shared/authentication/tcc-core-auth';
import { FontawesomeCoreModule } from './fontawesome-core.module';
registerLocaleData(nl);

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    VanDijkAppShellRoutingModule,
    FontawesomeCoreModule,
    AnimalSelectionShellModule.forRoot({
      source: 'app'
    }),
    TccCoreAuthModule.forRoot(),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    Diagnostic,
    AndroidPermissions
  ]
})
export class VanDijkAppShellModule {
  constructor(
    private _languageService: LanguageService,
    private _animationService: AnimationService,
  ) {
    this._languageService.initLang();
    this._animationService.initTheme();
  }
}

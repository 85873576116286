import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
})
export class FontawesomeCoreModule {
  constructor(
    lib: FaIconLibrary
  ) {
    lib.addIconPacks(fal);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tcc-mono-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent  {
  constructor( ) {

    // this.testSentry();

  }

  // testSentry() {
  //   console.log('test sentry');
  //   Sentry.captureException('App test');
  //   Sentry.nativeCrash();
  // }
}

import { getAppConfigProvider } from '@tcc-mono/shared/app-config';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

import { IonicModule } from '@ionic/angular';

import { AppComponent } from './app.component';

import { VanDijkAppShellModule } from '@tcc-mono/vandijk/app/shell/feature';
import { SharedTranslationModule } from '@tcc-mono/shared/translation';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { HttpClientModule } from '@angular/common/http';
import * as Sentry from "@sentry/capacitor";
import * as SentrySibling from "@sentry/angular-ivy";
import { Router } from '@angular/router';


Sentry.init(
  {
    dsn: "https://39f3cedf5651a129ae511aacffd6356b@sentry.tccapps.nl/5",
    // To set your release and dist versions
    release: 'vdv-app@2.0.2',
    dist: '1',
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
    integrations: [
      new SentrySibling.BrowserTracing({
        // Set "tracePropagationTargets" to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          'https://vandijk-api.tccapps.nl/'
        ],
        routingInstrumentation: SentrySibling.routingInstrumentation
      })
    ],
  },
  // Forward the init method to the sibling Framework.
  SentrySibling.init
);

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    IonicModule.forRoot(),
    HttpClientModule,
    SharedTranslationModule.forRoot(environment),
    VanDijkAppShellModule
  ],
  providers: [
    getAppConfigProvider(environment),
    BluetoothSerial,
    InAppBrowser,
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentrySibling.createErrorHandler(),
    },
    {
      provide: SentrySibling.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [SentrySibling.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export const environment = {
  // version: '1.1.0',
  production: false,
  apiUrl: 'https://vdv-api-develop.tccapps.nl/',
  authUrl: 'https://vdv-api-develop.tccapps.nl/',
  appCode: 'VDV-APP',
  tccCoreAuthentication: {
    clientId: '9a703f21-783c-4ff4-8e04-f94473dac281',
    redirectUrl: 'https://vandijk-app-develop.tccapps.nl/auth/callback'
  }
};
